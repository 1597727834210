import styles from '../Cart/DriverApp.module.scss';
import DynamicImageComponent from './DynamicImageRender/driverApp/page';
import React, { useState } from 'react';

const data = [
  {
    buttonText: 'Manage',
    imgPath: '/assets/img/products/cart/dr1.png',
    headline: "Manage Availability",
    description: "Toggle availability status for flexible scheduling."
  },
  {
    buttonText: 'Order Request',
    imgPath: '/assets/img/products/cart/dr2.png',
    headline: "Order Requests",
    description: "Receive instant notifications on new orders for timely responses."
  },
  {
    buttonText: 'Payments',
    imgPath: '/assets/img/products/cart/dr3.png',
    headline: "Manage Payments",
    description: "Track earnings and completed transactions."
  },
  {
    buttonText: 'Tracking',
    imgPath: '/assets/img/products/cart/dr4.png',
    headline: "Live Tracking",
    description: "Integrated GPS ensures accurate navigation to customer locations."
  },
  {
    buttonText: 'Profile Manage',
    imgPath: '/assets/img/products/cart/dr5.png',
    headline: "Profile Management",
    description: "Drivers can set up profiles with personal information and credentials."
  },
  {
    buttonText: 'Reviews',
    imgPath: '/assets/img/products/cart/dr6.png',
    headline: "Ratings & Reviews",
    description: "Rate customer interactions and report any issues, promoting safe, respectful service."
  },
  {
    buttonText: 'History',
    imgPath: '/assets/img/products/cart/dr7.png',
    headline: "Transaction History",
    description: "A log of completed deliveries for easy record-keeping."
  },
  {
    buttonText: 'Notification',
    imgPath: '/assets/img/products/cart/dr8.png',
    headline: "Push Notifications",
    description: "Real-time alerts for new orders, delivery status, and payment confirmations."
  },

];

const DriverApp = () => {
  const [selectedImage, setSelectedImage] = useState(data[0]?.imgPath || '');

  return (
    <section className={styles.realTimeVisibility}>
      <div className={styles.curvedCard}>
        <DynamicImageComponent data={data} selectedImage={selectedImage} setSelectedImage={setSelectedImage} />

        <div className={styles.mapContent}>
          <img src={selectedImage} alt="Fleet map and control interface" className={styles.mapImage} />
        </div>
      </div>
    </section>
  );
};

export default DriverApp;

import styles from '../Cart/CustomerApp.module.scss';
import DynamicImageComponent from './DynamicImageRender/whatsApp/page';

const data = [
  {
    buttonText: 'Browsing',
    imgPath: '/assets/img/products/cart/ca1.png',
    headline: "Store Menu Browsing",
    description: "Access a complete catalog from multiple grocery stores for enhanced variety and convenience."
  },
  {
    buttonText: 'Cart',
    imgPath: '/assets/img/products/cart/ca2.png',
    headline: "View Cart",
    description: "Enables order modifications and easy checkout."
  },
  {
    buttonText: 'Media Login',
    imgPath: '/assets/img/products/cart/ca3.png',
    headline: "Social Media Login",
    description: "Connect social accounts for simplified login and experience sharing."
  },
  {
    buttonText: 'Search stores',
    imgPath: '/assets/img/products/cart/ca4.png',
    headline: "Search Nearby Stores",
    description: "Locate and compare nearby stores to find the best deals."
  },
  {
    buttonText: 'Wishlist',
    imgPath: '/assets/img/products/cart/ca5.png',
    headline: "Add to Cart and Wishlist",
    description: "Add items for immediate or future purchase with easy cart and wishlist features."
  },
  {
    buttonText: 'Tracking',
    imgPath: '/assets/img/products/cart/ca6.png',
    headline: "Live Order Tracking",
    description: "Real-time tracking provides transparency and reassurance on order status."
  },
  {
    buttonText: 'In-App Payments',
    imgPath: '/assets/img/products/cart/ca7.png',
    headline: "In-App Payments",
    description: "A variety of secure payment options for added convenience."
  },
  {
    buttonText: 'Ratings',
    imgPath: '/assets/img/products/cart/ca8.png',
    headline: "Ratings & Reviews",
    description: "Customers can leave ratings and reviews directly in the app or WhatsApp, enhancing service accountability and quality."
  },
  {
    buttonText: 'History',
    imgPath: '/assets/img/products/cart/ca9.png',
    headline: "Transaction History",
    description: "Track all past orders for easy reordering and review."
  },

];

const CustomerApp = () => {
  return (
    <section className={styles.realTimeSection}>
      <div className={styles.topTagline}>
        <img src="/assets/img/products/painflower.png" alt="Decorative icon" className={styles.taglineIcon} />
        <p className={styles.taglineText}>
          <span className={styles.taglineTextSpan}> Customer app: </span> The Customer App provides a comprehensive shopping experience, allowing users to browse multiple stores, compare prices, and book orders for doorstep delivery.
        </p>
      </div>

      <div className={styles.topContent}>
        {/* <img src="/assets/img/products/OBJECTS.png" alt="Woman Illustration" className={styles.womanImage} /> */}
        <DynamicImageComponent data={data} />
      </div>
    </section>
  );
};

export default CustomerApp;

import React, { useState } from 'react';
import styles from './page.module.scss';

const DynamicImageComponent = ({ data }) => {
    const [selectedImage, setSelectedImage] = useState(data[0]?.imgPath || '');
    const [selectedHeadline, setSlectedHeadline] = useState(data[0]?.headline || '');
    const [selectedDescription, setSelectedDescription] = useState(data[0]?.description || '');

    return (
        <section className={styles.dynamicImageSection}>
            {/* Tabs */}
            <div className={styles.tabs}>
                <div className={styles.tabsInner}>
                    {data.map((item, index) => (
                        <button
                            key={index}
                            className={`${styles.tab} ${selectedImage === item.imgPath ? styles.active : ''}`}
                            onClick={() => {
                                setSelectedImage(item.imgPath);
                                setSlectedHeadline(item.headline);
                                setSelectedDescription(item.description);
                            }}
                        >
                            {item.buttonText}
                        </button>
                    ))}
                </div>
            </div>

            {/* Content */}
            <div className={styles.content}>
                <h2 className={styles.heading}>{selectedHeadline}</h2>
                <p className={styles.description}>
                    {selectedDescription}
                </p>

                <div className={styles.imageContainer}>
                    {/* Selected Image */}
                    <div className={styles.imageMockup}>
                        {selectedImage ? (
                            <img src={selectedImage} alt="Selected" className={styles.image} />
                        ) : (
                            <p className={styles.placeholderText}>No image selected</p>
                        )}
                    </div>
                </div>
            </div>
        </section>
    );
};

export default DynamicImageComponent;

import styles from '../Cart/outlet.module.scss';
import DynamicImageComponent from './DynamicImageRender/outlet/page';
import React, { useState } from 'react';

const data = [
  {
    buttonText: 'Adjustments',
    imgPath: '/assets/img/products/cart/ou1.png',
    headline: "Order Adjustments",
    description: "Managers can quickly modify orders based on customer requests or product availability. Whether it’s updating quantities, changing items, or adding special instructions, the adjustment feature makes real-time order customization easy."
  },
  {
    buttonText: 'Replacement',
    imgPath: '/assets/img/products/cart/ou2.png',
    headline: "Product Replacement Options",
    description: "For out-of-stock items, managers can suggest pre-maintained replacement products directly through the app, avoiding manual calls and follow-ups. The system learns from each replacement to make smarter suggestions in the future. Customers are notified and can approve replacements in-app, ensuring a seamless, efficient, and increasingly personalized experience."
  },
  {
    buttonText: 'Driver Assignment',
    imgPath: '/assets/img/products/cart/ou3.png',
    headline: "Driver Assignment for Local Deliveries",
    description: "Managers can assign available drivers to specific orders, enabling streamlined coordination between in-store staff and delivery personnel. This feature includes driver tracking and status updates, helping managers monitor the progress of each delivery for timely fulfillment."
  },
  {
    buttonText: 'Management',
    imgPath: '/assets/img/products/cart/ou4.png',
    headline: "Order Management and Processing",
    description: "View, accept, and process orders directly on the tablet, ensuring prompt preparation and an efficient in-store workflow."
  },
  {
    buttonText: 'Tracking',
    imgPath: '/assets/img/products/cart/ou5.png',
    headline: "Inventory Tracking and Low-Stock Alerts",
    description: "Monitor inventory in real time and set low-stock alerts to keep items in stock and maintain order accuracy."
  },
  {
    buttonText: 'Sales Performance',
    imgPath: '/assets/img/products/cart/ou6.png',
    headline: "Sales and Performance Dashboard",
    description: "A centralized dashboard provides sales data, bestsellers, and revenue insights, allowing outlet managers to make informed decisions quickly."
  },
  {
    buttonText: 'Feedback',
    imgPath: '/assets/img/products/cart/ou7.png',
    headline: "Customer Feedback Management",
    description: "Access ratings and feedback specific to the outlet, enabling direct responses to customer concerns and continuous improvement in service quality."
  },
  {
    buttonText: 'Integration',
    imgPath: '/assets/img/products/cart/ou8.png',
    headline: "Seamless Integration with Admin Panel",
    description: "All actions taken within the Outlet Manager App sync with the main Admin Panel, providing business owners with full visibility and control over all outlets."
  },

];

const OutletManager = () => {
  const [selectedImage, setSelectedImage] = useState(data[0]?.imgPath || '');

  return (
    <section className={styles.realTimeVisibility}>
      <div className={styles.curvedCard}>
        <DynamicImageComponent data={data} selectedImage={selectedImage} setSelectedImage={setSelectedImage} />

        <div className={styles.mapContent}>
          <img src={selectedImage} alt="Fleet map and control interface" className={styles.mapImage} />
        </div>
      </div>
    </section>
  );
};

export default OutletManager;

import React, { useState } from 'react';
import styles from './page.module.scss';

const DynamicImageComponent = ({ data, setSelectedImage, selectedImage }) => {
    // const [selectedImage, setSelectedImage] = useState(data[0]?.imgPath || '');
    const [selectedHeadline, setSelectedHeadline] = useState(data[0]?.headline || '');
    const [selectedDescription, setSelectedDescription] = useState(data[0]?.description || '');

    return (
        <section className={styles.dynamicImageSection}>
            {/* Tabs */}
            <div className={styles.tabs}>
                <div className={styles.tabsInner}>
                    {data.map((item, index) => (
                        <div
                            key={index}
                            className={`${styles.tab} ${selectedImage === item.imgPath ? styles.active : ''}`}
                            onClick={() => {
                                setSelectedImage(item.imgPath);
                                setSelectedHeadline(item.headline);
                                setSelectedDescription(item.description);
                            }}
                            role="button"
                            tabIndex={0} // Accessibility for keyboard navigation
                            onKeyDown={(e) => e.key === 'Enter' && setSelectedImage(item.imgPath)} // For keyboard users
                        >
                            {item.buttonText}
                        </div>
                    ))}
                </div>
            </div>

            {/* Content */}
            <div className={styles.content}>
                <h2 className={styles.heading}>{selectedHeadline}</h2>
                <p className={styles.description}>{selectedDescription}</p>
            </div>
        </section>
    );
};

export default DynamicImageComponent;

import HeroSection from "../../../components/Fleet/Cart/HeroSection";
import WhatsAppOrder from "../../../components/Fleet/Cart/WhatsAppOrder";
import CustomerApp from "../../../components/Fleet/Cart/CustomerApp";
import DriverApp from "../../../components/Fleet/Cart/driverApp";
import YellowHighlight from "../../../components/Fleet/Cart/YellowHighlight";
import OutletManager from "../../../components/Fleet/Cart/OutletManager";
import AdminPanel from "../../../components/Fleet/Cart/adminPanel";
import TechnologyAndSecurity from "../../../components/Fleet/Cart/technologyAndSecurity";
import GrowWithoutLimits from "../../../components/Fleet/Cart/GrowWithoutLimits";
import OtherFeatures from "../../../components/Fleet/Cart/OtherFeatures";
import Others from "../../../components/Fleet/Cart/others";
import Integration from "../../../components/Fleet/Cart/Integrations";
import Security from "../../../components/Fleet/Cart/Security";
import CallToAction from "../../../components/Fleet/Cart/CallToAction";
import FooterFive from "../../../components/footerFive";
import NavBar from "../../../components/NavBar";


const MainPage = () => {
  return (
    <>
      <NavBar />
      <HeroSection />
      <CustomerApp />
      <YellowHighlight title="Driver App" desc="The Driver App is designed to help drivers fulfill grocery orders efficiently." />
      <DriverApp />
      <YellowHighlight
        title="Outlet Manager Application"
        desc="X-Cart’s Tablet-Based Outlet Manager Application is designed for in-store use, empowering outlet managers with tools to efficiently handle orders, inventory, and delivery logistics. With features tailored for order adjustments, replacements, and driver assignment, each outlet can provide a smooth and responsive customer experience." />
      <OutletManager />
      {/* <OtherFeatures /> */}
      <YellowHighlight title="Admin Panel" desc="The Admin Panel provides grocery business owners with powerful tools to manage customers, inventory, orders, and deliveries." />
      <AdminPanel />
      <YellowHighlight title="Whats App" desc="The Customer App provides a comprehensive shopping experience, allowing users to browse multiple stores, compare prices, and book orders for doorstep delivery." />
      <WhatsAppOrder />
      <YellowHighlight
        title="Seamless Integration with FuzionBooks for Effortless Accounting"
        desc="X-Cart integrates effortlessly with FuzionBooks, allowing businesses to sync all sales, invoices, and expenses directly into a robust accounting system. With just a minute’s setup, X-Cart and FuzionBooks combine to streamline your entire financial process, offering:"
      />

      <Integration />
      <YellowHighlight title="Technology & Security" />
      <TechnologyAndSecurity />

      <CallToAction />
      <FooterFive />
    </>
  );
};

export default MainPage;

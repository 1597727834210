import styles from '../Cart/WhatsAppOrder.module.scss';
import DynamicImageComponent from './DynamicImageRender/customerApp/page';
import React, { useState } from 'react';

const data = [
    {
        buttonText: 'Orders',
        imgPath: '/assets/img/products/cart/wa1.png',
        headline: "Seamless Order Placement",
        description: "Customers can browse and select items within the WhatsApp interface, where orders can be processed with a few taps."
    },
    {
        buttonText: 'Tracking',
        imgPath: '/assets/img/products/cart/wa2.png',
        headline: "Real-Time Order Tracking via WhatsApp",
        description: "Customers receive real-time updates on their orders directly in WhatsApp, keeping them informed of every stage of the delivery."
    },
    {
        buttonText: 'Notifications',
        imgPath: '/assets/img/products/cart/wa3.png',
        headline: "WhatsApp Notifications for Deals and Discounts",
        description: "Notifications about exclusive discounts, deals, and personalized offers can be sent to customers via WhatsApp, enhancing customer engagement."
    },
    {
        buttonText: 'Ratings and Feedback',
        imgPath: '/assets/img/products/cart/wa4.png',
        headline: "Ratings and Feedback",
        description: "After receiving their order, customers can rate their experience directly within WhatsApp, providing instant feedback to improve service quality."
    },

];

const WhatsApp = () => {
    const [selectedImage, setSelectedImage] = useState(data[0]?.imgPath || '');

    return (
        <section className={styles.realTimeVisibility}>
            <div className={styles.curvedCard}>
                <DynamicImageComponent data={data} selectedImage={selectedImage} setSelectedImage={setSelectedImage} />

                <div className={styles.mapContent}>
                    <img src={selectedImage} alt="Fleet map and control interface" className={styles.mapImage} />
                </div>
            </div>
        </section>
    );
};

export default WhatsApp;

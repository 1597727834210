import styles from '../Cart/admin.module.scss';
import DynamicImageComponent from './DynamicImageRender/admin/page';
import React, { useState } from 'react';

const data = [
  {
    buttonText: 'Dashboard',
    imgPath: '/assets/img/products/cart/ap1.png',
    headline: "Order Details & Dashboard Management",
    description: "Access detailed information on all orders and platform activity from one centralized dashboard."
  },
  {
    buttonText: 'Access Control',
    imgPath: '/assets/img/products/cart/ap2.png',
    headline: "Authentication & Access Control",
    description: "Manage user and driver access to maintain platform security."
  },
  {
    buttonText: 'Manage',
    imgPath: '/assets/img/products/cart/ap3.png',
    headline: "Orders Management",
    description: "Real-time tracking of all order statuses, from pending to delivered."
  },
  {
    buttonText: 'Discount',
    imgPath: '/assets/img/products/cart/ap4.png',
    headline: "Coupons, Discounts, and Offers",
    description: "Create, modify, and distribute promotional offers to drive sales."
  },
  {
    buttonText: 'Options',
    imgPath: '/assets/img/products/cart/ap5.png',
    headline: "Manage Add/Edit/Delete Options",
    description: "Admins have full control over vendor listings, products, and services."
  },
  {
    buttonText: 'Sub-Admin',
    imgPath: '/assets/img/products/cart/ap6.png',
    headline: "Sub-admin Creation",
    description: "Delegate tasks efficiently with customizable access for sub-admins."
  },
  {
    buttonText: 'Content Manage',
    imgPath: '/assets/img/products/cart/ap7.png',
    headline: "Content Management",
    description: "Keep platform content relevant and up-to-date for an enhanced customer experience."
  },
  {
    buttonText: 'Reports',
    imgPath: '/assets/img/products/cart/ap8.png',
    headline: "Reports & Analytics",
    description: "Generate detailed reports to monitor sales, user behavior, and overall platform performance."
  }

];

const RealTimeVisibility = () => {
  return (
    <section className={styles.realTimeSection}>


      <div className={styles.topContent}>
        {/* <img src="/assets/img/products/OBJECTS.png" alt="Woman Illustration" className={styles.womanImage} /> */}
        <DynamicImageComponent data={data} />
      </div>
    </section>
  );
};

export default RealTimeVisibility;

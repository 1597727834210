import React, { useState } from "react";
import Headroom from "react-headroom";
import { FaSearch } from "react-icons/fa";
import { Link } from "react-router-dom";
import config from "../utils/config";

const NavBar = () => {
  const [active, setActive] = useState(false);
  const [searchShow, setSearchShow] = useState(false);
  const [subMenuActive, setSubMenuActive] = useState(false);
  const [tailorSubMenu, setTailorSubMenu] = useState(false);
  const [whoWeAreSubMenu, setWhoWeAreSubMenu] = useState(false);

  const menuActive = () => {
    setActive(!active);
  };

  const searchActive = () => {
    setSearchShow(!searchShow);
  };

  const toggleSubMenu = () => {
    setSubMenuActive(!subMenuActive);
  };

  const toggleWhoWeAreSubMenu = () => {
    setWhoWeAreSubMenu(!whoWeAreSubMenu);
  };
  const toggleTailorSubMenu = () => {
    setTailorSubMenu(!tailorSubMenu);
  };


  return (
    <>
      {/* Search popup */}
      <div
        className={searchShow ? "td-search-popup active" : "td-search-popup "}
        id="td-search-popup"
      >
        <form action="/" className="search-form">
          <div className="form-group">
            <input
              type="text"
              className="form-control"
              placeholder="Search....."
            />
          </div>
          <button type="submit" className="submit-btn">
            <FaSearch />
          </button>
        </form>
      </div>

      {/* Body overlay for search */}
      <div
        onClick={searchActive}
        className={searchShow ? "body-overlay active" : "body-overlay"}
        id="body-overlay"
      ></div>

      {/* Navbar wrapped with Headroom */}
      <Headroom style={{ zIndex: 100 }}>
        <nav className="navbar navbar-area navbar-expand-lg">
          <div className="container nav-container navbar-bg">
            <div className="responsive-mobile-menu">
              <button
                onClick={menuActive}
                className={
                  active
                    ? "menu toggle-btn d-block d-lg-none open"
                    : "menu toggle-btn d-block d-lg-none"
                }
                data-target="#itech_main_menu"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                {/* Logo */}
                <div
                  className="logoimage"
                  style={{ height: "auto", display: "inline-block" }}
                >
                  <Link to="/">
                    <img
                      // src="assets/img/fuzionest.png"
                      src={`${config.baseUrl}/assets/img/fuzionest.png`}
                      alt="Fuzionest"
                      style={{
                        height: "auto",
                        maxWidth: "30%",
                        border: "none",
                        display: "block",
                        margin: "5px 30px",
                        WebkitBorderRadius: "5px",
                      }}
                    />
                  </Link>
                </div>
                <span className="icon-left" />
                <span className="icon-right" />
              </button>
            </div>

            {/* Logo */}
            <div className="logo d-none d-lg-block">
              <Link to="/">
                <img
                  // src="assets/img/landing/FuzionestLogo.svg"
                  src={`${config.baseUrl}/assets/img/landing/FuzionestLogo.svg`}
                  alt="fuzionest"
                  style={{ border: "none", borderBottom: "none" }}
                />
              </Link>
            </div>

            {/* Main Menu */}
            <div
              className={
                active
                  ? "collapse navbar-collapse sopen"
                  : "collapse navbar-collapse"
              }
              id="itech_main_menu"
              style={{ padding: active ? "20px" : "" }}
            >
              <ul className="navbar-nav menu-open text-lg-end">
                {/* Home */}

                <li
                  className="menu-item-has-children"
                /* onClick={toggleWhoWeAreSubMenu} */
                >
                  <Link to="/about">Who we are</Link>
                  {/*  {whoWeAreSubMenu && (
                    <ul className="submenu1">
                      <li>
                        <Link to="/about">About Us</Link>
                      </li>
                      <li>
                        <Link to="/life-at-fuzionest">Life @ Fuzionest</Link>
                      </li>
                      <li>
                        <Link to="/careers">Careers</Link>
                      </li>
                      <li>
                        <Link to="/blogs">Blogs</Link>
                      </li>
                    </ul>
                  )} */}
                </li>

                <li>
                  <Link
                    className={
                      window.location.pathname.includes("/technology")
                        ? "active"
                        : ""
                    }
                    to="/technology"
                  >
                    What we do
                  </Link>
                </li>

                {/* Technology */}
                <li>
                  <Link
                    className={
                      window.location.pathname.includes("/service")
                        ? "active"
                        : ""
                    }
                    to="/service"
                  >
                    How we engage
                  </Link>
                </li>

                {/* Tailored Solutions */}
                {/* <li className="menu-item-has-children">
                  <Link
                    className={
                      window.location.pathname.includes("/tailored-solutions")
                        ? "active"
                        : ""
                    }
                    to="/tailored-solutions"
                    onClick={toggleSubMenu}
                  >
                    Tailored Solutions
                  </Link>
                </li> */}


                <li
                  className="menu-item-has-children"
                  onClick={toggleTailorSubMenu}
                >
                  <Link>Tailored Solutions</Link>
                  {tailorSubMenu && (
                    <ul className="submenu1">
                      <li>
                        <Link to="/products/fuzion-books">Fuzion Books</Link>
                      </li>
                      <li>
                        <Link to="/products/ridex">RideX - Ride Management ERP</Link>
                      </li>
                      <li>
                        <Link to="/products/x-cart">X-cart - Ecommerce</Link>
                      </li>
                    </ul>
                  )}
                </li>

                {/* Blog */}
                {/* <li className="menu-item-has-children">
                  <Link
                    className={
                      window.location.pathname.includes("/about")
                        ? "active"
                        : ""
                    }
                    to="/about"
                  >
                    Who we are
                  </Link>
                </li> */}



                <li className="menu-item-has-children">
                  <Link to="/blogs">Blogs</Link>
                </li>

                {/* About Us */}
                {/* <li className="menu-item-has-children">
                  <Link
                    className={
                      window.location.pathname.includes("/about")
                        ? "active"
                        : ""
                    }
                    to="/about"
                  >
                    ABOUT US
                  </Link>
                </li> */}

                {/* Contact */}
                <li className="menu-item-has-children">
                  <Link
                    className={
                      window.location.pathname.includes("/contact")
                        ? "active"
                        : ""
                    }
                    to="/contact"
                  >
                    <button className="btn btn-base border-radius-0">
                      Contact Us
                    </button>
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </Headroom>
    </>
  );
};

export default NavBar;

import styles from './CallToAction.module.scss';
import { Link } from "react-router-dom";

const CallToAction = () => {
  return (
    <section className={styles.callToActionSection}>
      <div className={styles.textWrapper}>
        <p className={styles.introText}>
          We also offer customizable plans based on your unique business needs, <span className={styles.highlight}>fleet</span> size, and specific integrations. Contact us to discuss a plan tailored to your requirements.
        </p>

        <div className={styles.headingWrapper}>
          <img
            src="/assets/img/products/yellowLineSmall.png"
            alt="Decorative Yellow Lines"
            className={styles.yellowLineSmall}
          />
          <h2>
            Want to see
          </h2>
          <h2>
            <span className={styles.highlight}>X - Cart</span>  in action ?
          </h2>
          <img
            src="/assets/img/products/yellowLines.png"
            alt="Decorative Yellow Lines"
            className={styles.yellowLinesLarge}
          />
        </div>


      </div>
      <Link to="/products/fuzion-books/start-now">
        <button className={styles.ctaButton}>Start now - It's free</button>
      </Link>
    </section>
  );
};

export default CallToAction;

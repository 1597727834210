import React from 'react';
import DynamicCards from './DynamicCards/DynamicCards';

const App = () => {
    const cardData = [
        { headline: '1. Automated Transaction Sync', description: 'All orders, payments, refunds, and expenses from X-Cart are automatically recorded in FuzionBooks, eliminating manual data entry and reducing errors.' },
        { headline: '2. Real-Time Financial Insights', description: 'Access up-to-date revenue and expense reports, allowing for real-time financial insights and smarter business decisions.' },
        { headline: '3. Simplified Tax and Compliance Reporting', description: 'FuzionBooks keeps your tax records and compliance needs in check, helping you stay ahead of reporting requirements effortlessly.' },
        { headline: '4. Customizable Invoices and Statements', description: 'Generate custom invoices and financial statements to match your brand’s style, making client interactions smooth and professional.' },
        { headline: '5. Expense and Inventory Tracking', description: 'Get a clear view of all operational costs and inventory levels, helping you manage cash flow and stock efficiently.' },
    ];

    return (
        <>
            <DynamicCards data={cardData} />
        </>
    );
};

export default App;

import React from 'react';
import styles from './DynamicCards.module.scss';

const DynamicCards = ({ data }) => {
    return (
        <div className={styles.parentContainer}>
            <div className={styles.cardContainer}>
                {data.map((item, index) => (
                    <div
                        key={index}
                        className={`${styles.card} ${index % 2 === 0 ? styles.oddCard : styles.evenCard
                            }`}
                    >
                        <h3 className={styles.headline}>{item.headline}</h3>
                        <p className={styles.description}>{item.description}</p>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default DynamicCards;

import styles from './YellowHighlight.module.scss';

const YellowHighlight = ({ title, desc }) => {
    return (
        <section className={styles.realTimeTrackingSection}>
            <div className={styles.contentWrapper}>
                <div>
                    <h2>
                        {title}
                    </h2>
                </div>

                <div className={styles.para}>
                    <p>{desc}</p>
                </div>
            </div>

        </section>
    );
};

export default YellowHighlight;

import styles from "./HeroSection.module.scss";
import { Link, useNavigate } from "react-router-dom";


const HeroSection = () => {

  const navigate = useNavigate();

  const handleStartNowClick = () => {
    navigate("/products/fuzion-books/start-now");
  };

  const handleDemoClick = () => {
    navigate("https://calendly.com/contact-mmm");
  };


  return (
    <section className={styles.hero}>
      <h1>
        <span className={styles.highlight}>X-Cart</span>
      </h1>
      <div className={styles.para}>
        <p className={styles.para_Ptag}>
          eCommerce Application: Multi-Vendor, GenAI-Powered, and Highly Customizable
        </p>
      </div>
      <div className={styles.para}>
        <p >
          X-Cart is a globally recognized multi-vendor eCommerce solution powered by GenAI, designed for seamless grocery delivery experiences. It’s packed with features for customers, drivers, and admins alike, ensuring high customization and usability.
        </p>
      </div>

      <div className={styles.buttons}>
        <button className={styles.primaryBtn} onClick={handleStartNowClick}>Start now - It's free</button>
        <Link to="https://calendly.com/contact-mmm">
          <button className={styles.secondaryBtn}>Schedule a demo</button>
        </Link>
      </div>

    </section>
  );
};

export default HeroSection;
